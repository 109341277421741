<template>
    <div id="sidebar">
        <transition name="sidebar">
            <div class="sidemenu" v-if="!mobile || showSideMenu">
                <header class="sidemenu-top">
                    <font-awesome-icon v-if="mobile && showSideMenu" class="close-icon" icon="times" @click="showSideMenu = false"/>
                    <router-link to="/"><h1>MailPal</h1></router-link>
                </header>
                <aside class="category-wrapper">
                    <div class="category-header">
                        <h2>Categories</h2>
                        <div class="category-add-icon" @click="toggleShowNewCategory" v-tooltip="{ content: showNewCategory ? 'Close new category section' : 'Create a new category'}">
                            <font-awesome-icon :class="{'rotateIcon': showNewCategory}" icon="plus" />
                        </div>
                    </div>
                    <div class="category-list">
                        <Category v-if="status === 'done'" v-bind="{ name: 'All', color: 'var(--primary)', addresses: addresses, _id: 'all' }" @click.native="switchCategory('all')"/>
                        <transition name="expand">
                            <div v-if="showNewCategory" class="category-add-wrapper">
                                <input class="category-add-input" placeholder="Name" v-model="newCategoryName" />
                                <SliderPicker class="category-add-color-input" v-model="newCategoryColor" />
                                <button class="primary-button category-add-button" @click="addCategory">Create</button>
                            </div>
                        </transition>
                        <transition name="fade" mode="in-out">
                            <div v-if="status === 'done'">
                                <Category v-for="category in categories" :key="category.name" v-bind="{ ...category, addresses: addresses.filter((item) => item.category !== undefined && item.category._id === category._id) }" @click.native="switchCategory(category._id)"/>
                            </div>
                            <div v-else-if="status === 'loading'">
                                <div class="category-skeleton" v-for="key in 6" :key="'_' + key"></div>
                            </div>
                        </transition>
                    </div>
                </aside>
                <div class="sidemenu-bottom">
                    <nav class="sidemenu-bottom-list">
                        <a href="/settings"><font-awesome-icon icon="cog"/>Settings</a>
                        <a @click="logout"><font-awesome-icon icon="sign-out-alt"/>Logout</a>
                        <a href="/help"><font-awesome-icon icon="life-ring"/>Help</a>
                    </nav>
                </div>
            </div>
        </transition>
        <div v-if="mobile || !showSideMenu" class="menu-icon">
            <font-awesome-icon icon="bars" @click="showSideMenu = true"/>
        </div>
    </div>
</template>

<script>
    import Category from '@/components/Category'
    import { Global_Mutation } from '../store/types'
    import { Action, Mutation } from '../store/category/types'
    import { Action as AuthAction } from '../store/auth/types'
    import SliderPicker from 'vue-color/src/components/Slider'

    export default {
        name: 'SideMenu',
        data(){
            return {
                showNewCategory: false
            }
        },
        components: {
            Category,
            SliderPicker
        },
        computed: {
            categories: function(){
               return this.$store.state.category.categories;
            },
            addresses: function(){
               return this.$store.state.address.addresses;
            },
            newCategoryName: {
                get: function(){
                    return this.$store.state.category.newCategory.name;
                },
                set: function(newValue){
                    document.title = "New: " + this.capitalize(newValue) + " - MailPal"
                    this.$store.commit(Mutation.UPDATE_NEW_CATEGORY_NAME, newValue); 
                }
            },
            newCategoryColor: {
                get: function(){
                    return this.$store.state.category.newCategory.color;
                },
                set: function(newValue){
                    this.$store.commit(Mutation.UPDATE_NEW_CATEGORY_COLOR, newValue.hex); 
                }
            },
            showSideMenu: {
                get: function(){
                    return this.$store.state.showSideMenu;
                },
                set: function(newValue){
                    this.$store.commit(Global_Mutation.CHANGE_SHOW_SIDE_MENU, newValue); 
                }
            },
            mobile: function(){
                return this.$store.state.mobile;
            },
            status: function(){
                return this.$store.state.category.status;
            },
        },
        methods: {
            switchCategory: function(id){
                this.$store.commit(Mutation.SWITCH_CATEGORY, id);
            },
            addCategory: function(){
                this.$store.dispatch(Action.ADD_CATEGORY);
                this.showNewCategory = false;
            },
            logout: function(){
                this.$store.dispatch(AuthAction.LOGOUT);
            },
            toggleShowNewCategory: function(){
                if(this.showNewCategory){
                    this.resetCategoryTitle()
                    this.showNewCategory = false;
                    this.$store.commit(Mutation.UPDATE_NEW_CATEGORY_NAME, '');
                }else{
                    document.title = ((this.newCategoryName.length > 0) ? "New: " + this.capitalize(this.newCategoryName) : "Add Category") + " - MailPal";
                    this.showNewCategory = true;
                }
            }
        },
        created(){
            this.$store.dispatch(Action.GET_CATEGORIES); 
        }
    }
</script>

<style>
    .sidemenu{
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 300px;
        z-index: 1;
        background: var(--background-sidemenu);
        display: flex;
        flex-direction: column;
    }

    a{
        color: var(--font);
        text-decoration: none;
    }

    .menu-icon{
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 25px;
        cursor: pointer;
    }

    .close-icon{
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 25px;
        cursor: pointer;
    }

    .sidebar-enter-active {
        animation: move-in 0.4s;
    }
    .sidebar-leave-active {
        animation: move-in 0.4s reverse;
    }
    @keyframes move-in {
        0% {
            left: -300px;
        }
        100% {
            left: 0;
        }
    }

    .sidemenu-top{
        text-align: center;
        border-bottom: 3px solid var(--background);
    }

    .sidemenu-top h1{
        color: var(--font-light);
    }

    .search-wrapper{
        max-width: 85%;
        height: 38px;
        margin: auto;
        margin-bottom: 2rem;
    }

    .category-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 85%;
        margin: auto;
    }

    .category-header h2{
        margin-left: 0;
    }

    .category-add-icon{
        margin-left: auto;
        background: var(--background-accent);
        padding: 8px;
        border-radius: 5px;
        width: 15px;
        height: 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .category-add-icon:hover{
        filter: brightness(110%);
    }

    .category-add-icon:active{
        transform: scale(0.95);
    }

    .category-add-icon svg{
        color: var(--font-light);
        transition: all .2s ease;
    }

    .rotateIcon{
        transform: rotate(45deg);
    }

    .category-wrapper{
        overflow-y: auto;
        height: calc(100% - 220px);
    }

    .category-list{
        max-width: 85%;
        margin: auto;
    }

    .category-wrapper::-webkit-scrollbar {
        width: 6px;
    }
    .category-wrapper::-webkit-scrollbar-track {
        background: transparent;
    }
    .category-wrapper::-webkit-scrollbar-thumb {
        background-color: var(--background);
        border-radius: 3px;
    }
    .category-wrapper {
        scrollbar-width: thin;
        scrollbar-color: var(--background) transparent;
    }

    .sidemenu-bottom{
        border-top: 3px solid var(--background);
        background: var(--background-sidemenu);
        width: 100%;
        position: absolute;
        bottom: 0;
    }

    .sidemenu-bottom-list{
        margin-left: 1rem;
        font-size: 18px;
        font-weight: 650;
        color: var(--font-lighter);
    }

    .sidemenu-bottom-list a{
        color: var(--font-lighter);
        text-decoration: none;
        display: block;
        margin: 1rem;
        cursor: pointer;
    }

    .sidemenu-bottom-list svg{
        margin-right: 1rem;
    }

    .category-add-wrapper{
        background: var(--background);
        text-align: center;
        border-radius: 5px;
        margin-bottom: 0.5rem;
        padding: 1rem 0px;
        max-height: 200px;
    }

    .expand-enter-active {
        animation: expand-in 0.3s;
    }
    .expand-leave-active {
        animation: expand-in 0.3s reverse;
    }

    .expand-leave-active .stats-mobile{
        opacity: 0;
    }

    .expand-enter-active .stats-mobile{
        opacity: 0;
    }

    @keyframes expand-in {
        0% {
            max-height: 0;
            opacity: 0;
        }
        20% {
            opacity: 0;
        }
        85% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }

    .category-add-button{
        background: var(--primary);
        color: var(--font-dark);
        font-size: 14px;
        border-bottom: 5px solid #63C6B1;
        display: block;
        margin: auto;
        margin-top: 1rem;
    }

    .category-add-input{
        margin: auto;
        border: none;
        background: var(--background-dark);
        box-shadow: none;
        color: var(--font-light);
        font-weight: 700;
        width: 80%;
        padding: 10px;
        margin-bottom: 1rem;
    }

    .category-add-color-input{
        width: 80%;
        margin: auto;
    }

    .category-skeleton{
        background: var(--background);
        border-radius: 5px;
        height: 40px;
        margin-bottom: 0.5rem;
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .darker {
        background: var(--background-dark);
    }

    .category-skeleton::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(-100%);
        height: 100%;
        width: 100%;
        background: linear-gradient(to right, transparent 0%, var(--background-sidemenu) 50%, transparent 100%);
        animation: load 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    @keyframes load {
        from {
            transform: translateX(-100%);
        }
        to   {
            transform: translateX(100%);
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.2s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }

</style>