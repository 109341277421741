<template>
    <div class="banner-wrapper">
        <div class="banner-content-wrapper">
            <font-awesome-icon icon="times" class="close-icon" @click="closeBanner" />
            <div class="banner-head">
                <h1>{{headline}}</h1>
            </div>
            <div class="banner-content">
                <slot></slot>
            </div>
        </div>
        <div class="art-wrapper">
           <img id="brief-image" src="/static/images/email.png" >
        </div>
    </div>
</template>

<script>
    import { Global_Mutation } from '../store/types'

    export default {
        name: 'Banner',
        props: {
            headline: String
        },
        methods: {
            closeBanner(){
                /*
                    Store datetime in local storage and only show banner if 24 hours have passed
                */
                localStorage.setItem('show_banner', new Date());
                this.$store.commit(Global_Mutation.CHANGE_SHOW_BANNER, false);
            }
        }
    }
</script>

<style scoped>
    .banner-wrapper{
        display: flex;
        background: var(--background-light);
        border: 2px solid var(--background-accent);
        border-radius: 10px;
        padding: 20px 30px;
        position: relative;
        height: auto;
        margin-top: 5rem;
    }

    .art-wrapper{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .art-wrapper img{
        max-width: 150px;
    }

    .result{
        display: flex;
        align-items: center;
    }

    .banner-content-wrapper {
        width: 50%;
    }

    .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .banner-head h1 {
        margin: 0;
    }

    .banner-content{
        max-width: 400px;
        color: var(--font-light);
        margin-top: 5px;
    }
</style>