<template>
    <div id="mailpal-html">
        <side-menu />
        <transition name="fly-away">
            <new-address-button v-if="!limitReached && !showCreateAddress" @click.native="showCreateAddress = !showCreateAddress"/>
        </transition>
        <main class="content-wrapper" :class="{ 'showSideMenu': showSideMenu}">
            <div class="home-content">
                <transition name="appear" mode="out-in">
                    <limit-reached v-if="limitReached" />
                    <create-address v-else-if="!limitReached && showCreateAddress" />
                    <banner v-else-if="bannerValue && !showCreateAddress" headline="Welcome back!">
                        <p>Here are your latest stats:</p>
                        <ul>
                            <li>{{user.stats.blocked}} blocked emails</li>
                            <li>{{user.stats.forwarded}} forwarded emails</li>
                        </ul>
                        <p>All that in just one month 💪</p>
                    </banner>
                </transition>
                <div class="address-list-wrapper">
                    <div class="address-list-header">
                        <h1>{{currentCategoryText}}</h1>
                        <div class="filter-icon" @click="showFilter = !showFilter">
                            <span v-if="!showFilter"><font-awesome-icon icon="filter" /> Open Filter</span>
                            <span v-else><font-awesome-icon icon="times" /> Close filter</span>
                        </div>
                        <div v-if="status === 'done' && currentCategory.id !== 'all'" class="delete-category-wrapper" v-bind:style= "[addresses.length > 0 ? { marginLeft: 'auto' } : { marginLeft: '1rem' }]" v-tooltip="{ content: 'Delete Category' }" @click="deleteCategory">
                            <p>Delete</p>
                        </div>    
                    </div>
                    <div v-if="showFilter" class="filter-list">
                        <search />
                        <dropdown v-if="status === 'done' && addresses.length > 0" :value="'Sort after: ' + capitalize(sortAfter.toLowerCase())" class="option-dropdown" color="var(--background-accent)" @keypress.native="handleEnter" >
                            <p tabindex="0" v-for="option in sortList" :key="'key-' + option.name" @click="sortAfterChange(option.name)">{{capitalize(option.name.toLowerCase())}}</p>
                        </dropdown>
                        <div v-if="status === 'done' && addresses.length > 0" class="order-icon" @click="sortOrderToggle()" v-tooltip="{ content: 'Change order'}">
                            <font-awesome-icon :icon="sortOrderIcon" />
                        </div>
                        <div v-if="status === 'done' && storedLength > 0" class="filter-status" @click="toggleFilterAddressStatus" v-tooltip="{ content: 'Filter after address status' }">
                            <span v-if="filterAddressStatus === 'active'" class="active">Show: Active</span>                     
                            <span v-else-if="filterAddressStatus === 'inactive'" class="blocked">Show: Inactive</span>
                            <span v-else >Show: All</span>
                        </div>
                    </div>
                    <transition name="fade" mode="in-out">
                        <div class="address-list" v-if="status === 'done' && addresses.length > 0">
                            <Address v-for="address in addresses" :key="address._id" v-bind="address" />
                        </div>
                        <div class="address-list" v-else-if="status === 'loading'">
                            <div class="address-skeleton" v-for="key in 5" :key="'_' + key"></div>
                        </div>
                    </transition>
                    <nothing-to-show v-if="status === 'done' && addresses.length === 0" message="Nothing to show" />
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
    import SideMenu from '@/components/SideMenu'
    import Footer from '@/components/FooterHome'
    import Address from '@/components/Address'
    import Dropdown from '@/components/Dropdown'
    import NothingToShow from '@/components/NothingToShow'
    import CreateAddress from '@/components/CreateAddress'
    import Banner from '@/components/Banner'
    import Search from '@/components/atoms/Search'
    //import UsageHome from '@/components/UsageHome'
    import NewAddressButton from '@/components/atoms/NewAddressButton'
    import LimitReached from '@/components/LimitReached'
    import { Action, Mutation } from '../store/address/types'
    import { Action as CategoryAction } from '../store/category/types'
    import { Action as UserAction} from '../store/user/types'
    import { Global_Mutation } from '../store/types'
    import { Sort_Options, sortAddresses } from '../utils/sortAddresses'

    export default {
        name: 'Home',
        data(){
            return {
                showDropdownList: false,
                showFilter: false,
                filterAddressCurrent: 0,
                filterAddressOptions: ['all', 'active', 'inactive']
            }
        },
        computed: {
            addresses: function(){
                return sortAddresses(this.$store.state.address.addresses, this.$store.state.category.currentCategory.id, this.$store.state.address.search, this.$store.state.address.sort.after, this.$store.state.address.sort.order, this.filterAddressOptions[this.filterAddressCurrent])
            },
            storedLength(){
                return this.$store.state.address.addresses.length;
            },
            currentCategory: function(){
                return this.$store.state.category.currentCategory;
            },
            currentCategoryText: function(){
                if(this.currentCategory.id === "all"){
                    return "All addresses"
                }else{
                    return "Category: " + this.capitalize(this.currentCategory.name)
                }
            },
            sortAfter: function(){
                return this.$store.state.address.sort.after;
            },
            sortOrder: function(){
                return this.$store.state.address.sort.order;
            },
            filterAddressStatus(){
                return this.filterAddressOptions[this.filterAddressCurrent];
            },
            sortList: function () {
                let list = [];
                const options = Object.entries(Sort_Options);
                for (const [key, value] of options) {
                    if(key !== this.$store.state.address.sort.after){
                        list.push({ name: key, value: value });
                    }
                }
                return list
            },
            showSideMenu: {
                get: function(){
                    return this.$store.state.showSideMenu;
                }
            },
            status: function(){
                return this.$store.state.status;
            },
            sortOrderIcon: function(){
                let orientation = (this.sortOrder === 'ASC') ? 'down' : 'up';
                let icon = (this.sortAfter === 'CREATED' || this.sortAfter === 'USED') ? 'numeric' : 'alpha';
                return `sort-${icon}-${orientation}`;
            },
            showCreateAddress: {
                get: function(){
                    return this.$store.state.address.showNewAddress;
                },
                set: function(newValue){
                    this.$store.commit(Mutation.UPDATE_NEW_ADDRESS_SHOW, newValue); 
                }
            },
            bannerValue: {
                get: function () {
                    return this.$store.state.showBanner;
                },
                set: function (newValue) {
                    this.$store.commit(Global_Mutation.CHANGE_SHOW_BANNER, newValue);
                }
            },
            user: function(){
                return this.$store.state.user;
            },
            isfreeTier: function(){
                return this.$store.state.user.subscription.name === 'free';
            },
            limitReached: function(){
                if(!this.$store.state.user.loaded){
                    return false
                }else if(this.$store.state.user.subscription.name !== 'free'){
                    return false
                }
                const emails = this.$store.state.user.stats.forwarded >= this.$store.state.user.quota.emails;
                const addresses = this.$store.state.user.addresses >= this.$store.state.user.quota.addresses;
                return emails || addresses;
            },
        },
        methods: {
            sortAfterChange(value){
                this.$store.commit(Mutation.UPDATE_SORT_AFTER, value);
            },
            sortOrderToggle(){
                this.$store.commit(Mutation.UPDATE_SORT_ORDER, (this.sortOrder === "ASC") ? "DESC" : "ASC");
            },
            toggleFilterAddressStatus(){
                let i = this.filterAddressCurrent + 1;
                this.filterAddressCurrent = i % this.filterAddressOptions.length;
            },
            deleteCategory(){
                this.$store.dispatch(CategoryAction.DELETE_CATEGORY, this.currentCategory.id);
            },
            handleEnter: function(e){
                const keycode = e.keyCode ? e.keyCode : e.which;
                if(keycode === 13){
                    e.target.click();
                }
            },
            shouldShowBanner(){
                /* 
                    Always show banner except user closed it and 24 hours haven't passed
                    - if user closes banner, datetime is stored in local storage
                    - one created check if value exists and check if 24 hours have passed 
                */ 
                const period = 1000 * 60 * 60 * 24; //One day
                const bannerValue = localStorage.getItem('show_banner');

                if(bannerValue === null){
                    return this.showBanner();
                }
                
                const date = new Date(bannerValue);
                if(String(date) === "Invalid Date"){
                    return this.showBanner();
                }

                const now = new Date();
                if(now - date > period){
                    return this.showBanner();
                }
            },
            showBanner(){
                this.$store.commit(Global_Mutation.CHANGE_SHOW_BANNER, true);
            },
        },
        components: {
            SideMenu,
            Footer,
            Address,
            Dropdown,
            NothingToShow,
            CreateAddress,
            //UsageHome,
            NewAddressButton,
            LimitReached,
            Banner,
            Search
        },
        created (){
            addEventListener('resize', () => {
                this.$store.commit(Global_Mutation.CHANGE_MOBILE, innerWidth <= 1200);
            })
            this.$store.dispatch(Action.GET_ADDRESSES);
            this.$store.dispatch(UserAction.GET_USER);
            document.body.parentNode.style.background = "var(--background)";
            this.resetCategoryTitle();
            this.shouldShowBanner()
        },
        watch: {
            showSideMenu: function(newValue){
                if(newValue){
                    document.body.style.overflowY = "hidden";
                }else{
                    document.body.style.overflowY = null;
                }
            },
            currentCategory: function(newValue){
                if(newValue.id === "all"){
                    document.title = "All Addresses - MailPal";
                }else{
                    document.title = this.capitalize(newValue.name) + " - MailPal";
                }
            },

        }
    }
</script>

<style>

    #mailpal-html{
        overflow-x: hidden;
    }

    .content-wrapper {
        margin-left: 300px;
        margin-right: calc(100% - 100vw);
        transition: all 0.4s;
        transition-delay: 0s;
        min-height: 100vh;
    }

    @media only screen and (max-width: 1200px) {
        .content-wrapper{
            margin-left: 0px;
            margin-right: calc(100% - 100vw);
            transition: all 0.4s;
            transition-delay: 0.2s;
        }
        
    }

    .showSideMenu{
        opacity: 0.2;
    }

    .home-content {
        max-width: 85%;
        margin: auto;
        margin-top: 4rem;
    }

    .address-list-header{
        display: flex;
        align-items: center;
    }

    .address-list-wrapper{
        margin-bottom: 4rem;
    }

    .filter-icon{
        background: var(--background-accent);
        border-radius: 5px;
        padding: 10px 13px;
        margin-left: auto;
        cursor: pointer;
    }

    .filter-list{
        display: flex;
        margin-bottom: 1rem;
        height: 40px;
    }

    .option-dropdown {
        margin-left: 1rem;
        background: var(--background-accent);
        border-radius: 5px;
    }

    .order-icon{
        background: var(--background-accent);
        border-radius: 5px;
        padding: 10px 13px;
        margin-left: 1rem;
        cursor: pointer;
    }

    .order-icon svg{
        transition: all 0.3s;
    }

    .icon-flip{
        transform: rotate(180deg);
    }

    .filter-status{
        background: var(--background-accent);
        border-radius: 5px;
        padding: 10px 13px;
        margin-left: 1rem;
        cursor: pointer;
    }

    .filter-status svg {
        margin-right: 0.5rem;
    }

    .delete-category-wrapper{
        background: var(--background-accent);
        border-radius: 5px;
        height: 40px;
        display: flex;
        align-items: center;
        transition: background 0.2s ease-in-out;
        cursor: pointer;
    }

    .delete-category-wrapper:hover{
        background: var(--red);
    }

    .delete-category-wrapper p{
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .address-skeleton{
        width: 100%;
        margin-bottom: 1rem;
        position: relative;
        height: 60px;
        background: var(--background-light);
        border-radius: 5px;
        overflow: hidden;
    }

    .address-skeleton::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(-100%);
        height: 100%;
        width: 100%;
        background: linear-gradient(to right, transparent 0%, var(--background-accent) 50%, transparent 100%);
        animation: load 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    @keyframes load {
        from {
            transform: translateX(-100%);
        }
        to   {
            transform: translateX(100%);
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.2s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }

    .fly-away-enter-active {
        animation: fly .4s;
    }
    .fly-away-leave-active {
        animation: fly .4s reverse;
    }

    .appear-enter-active {
        animation: expand-in .4s;
    }
    .appear-leave-active {
        animation: expand-in .4s reverse;
    }

    @keyframes expand-in {
        0% {
            max-height: 0;
            opacity: 0;
        }
        20% {
            opacity: 0;
        }
        85% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            max-height: 350px;
        }
    }

    @keyframes fly {
        0% {
            top: -50px;
            opacity: 0;
        }
        20% {
            opacity: 1;
        }
        85% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }

    .tooltip {
        display: block !important;
        z-index: 10000;
    }

    .tooltip .tooltip-inner {
        background: var(--background-dark);
        color: var(--font);
        border-radius: 5px;
        padding: 5px 10px 4px;
    }

    .tooltip .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: var(--background-dark);
        z-index: 1;
    }

    .tooltip[x-placement^="top"] {
        margin-bottom: 5px;
    }

    .tooltip[x-placement^="top"] .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
    }

    .tooltip[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    .tooltip[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }

    @media only screen and (max-width: 1000px) {
        .address-list-wrapper{
            font-size: 14px;
        }

        .home-content{
            max-width: 90%;
        }
        .option-dropdown{
            margin-left: 1rem;
        }
    }

     @media only screen and (max-width: 600px) {
        .address-list-wrapper{
            font-size: 10px;
        }

        .home-content{
            font-size: 12px;
        }
    }
</style>