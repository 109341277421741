<template>
    <div class="dropdown-wrapper" :style="{'--color': color }">
        <button class="dropdown-preview" :class="{ 'no-border': noBorder}" @click.stop="toggleDropdown">
            <span>{{value}}</span>
            <font-awesome-icon class="dropdown-icon" :class="{ 'icon-flip': showDropdown}"  icon="caret-down" />
        </button>
        <div v-if="showDropdown" class="dropdown-list" v-click-outside="toggleDropdown" @click.stop="toggleDropdown">
            <slot></slot>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Dropdown',
        data(){
            return {
                showDropdown: false
            }
        },
        props: {
            value: String,
            color: {
                default: "var(--background-dark)",
                type: String
            },
            noBorder: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            toggleDropdown(){
                this.showDropdown = !this.showDropdown;
            }
        }
    }

</script>

<style scoped>

    .dropdown-wrapper {
        position: relative;
    }

    .dropdown-preview {
        all: unset;
        background: var(--color);
        border-radius: 5px;
        color: var(--font);
        display: flex;
        align-items: center;
        height: 40px;
        cursor: pointer;
        outline: 0;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        -webkit-text-fill-color: var(--font);
        white-space: nowrap;
    }

    .dropdown-preview:focus{
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
        filter: brightness(110%);
    }

    .dropdown-preview span {
        margin-left: 1rem;
        margin-right: 10px;
    }

    .no-border {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .dropdown-icon {
        margin-left: auto;
        margin-right: 1rem;
        transition: all 0.3s;
    }

    .icon-flip{
        transform: rotate(180deg);
    }

    .dropdown-list{
        background: var(--color);
        min-width: 100%;
        text-align: center;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
        width: 140px;
        border-radius: 5px;
        position: absolute;
        z-index: 1;
    }

    .dropdown-list p{
        margin: 0;
        padding: 10px 10px;
        cursor: pointer;
    }

    .dropdown-list p:hover{
        background: var(--color);
        filter: brightness(90%);
    }

    .dropdown-dot{
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin-right: 0.4rem;
        display: inline-block;
    }
</style>