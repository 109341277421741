<template>
    <div class="category-tag">
        <span class="category-tag-dot" :style="`background: ${color}`" />
        <p>{{name}}</p>
    </div>
</template>

<script>
    export default {
        name: 'CategoryTag',
        props: {
            name: String,
            color: String
        }
    }
</script>

<style scoped>
    .category-tag-wrapper{
        margin-left: 1rem;
        display: flex;
        justify-content: flex-end;
    }

    .category-tag{
        background: var(--background-accent);
        height: 40px;
        padding: 2px 10px;
        text-align: center;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
    }

    .category-tag-dot{
        width: 13px;
        height: 13px;
        border-radius: 13px;
        margin-right: 10px;
    }

    @media only screen and (max-width: 1000px) {
        .category-tag-wrapper{
            margin-left: 0.5rem;
        }

        .category-tag{
            font-size: 12px;
            height: 35px;
        }
    }

    @media only screen and (max-width: 600px) {
        .category-tag{
            height: 30px;
            font-size: 10px;
            padding: 2px 8px;
        }

        .category-tag-dot{
            width: 8px;
            height: 8px;
            border-radius: 8px;
            margin-right: 5px;
        }
    }
</style>