<template>
    <div class="nothing-to-show-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 368 368" xml:space="preserve" width="200px" height="200px"><g><circle class="emoji-light" cx="184" cy="184" r="176"/><g>
            <path class="emoji-dark" d="M184,224c-29.768,0-58.168,12.632-77.952,34.656c-2.952,3.288-2.68,8.344,0.608,11.296   c3.28,2.952,8.344,2.688,11.296-0.608C134.696,250.696,158.776,240,184,240c25.296,0,49.368,10.688,66.04,29.336   c1.576,1.768,3.768,2.664,5.96,2.664c1.896,0,3.808-0.672,5.336-2.032c3.288-2.952,3.568-8,0.624-11.296   C242.256,236.64,213.84,224,184,224z"/>
            <path class="emoji-dark" d="M184,0C82.536,0,0,82.544,0,184s82.536,184,184,184s184-82.544,184-184S285.464,0,184,0z M184,352   c-92.632,0-168-75.36-168-168S91.368,16,184,16s168,75.36,168,168S276.632,352,184,352z"/>
            <path class="emoji-dark" d="M280,128c-4.424,0-8,3.584-8,8c0,13.232-10.768,24-24,24s-24-10.768-24-24c0-4.416-3.576-8-8-8   s-8,3.584-8,8c0,22.056,17.944,40,40,40s40-17.944,40-40C288,131.584,284.424,128,280,128z"/>
            <path class="emoji-dark" d="M160,136c0-4.416-3.576-8-8-8s-8,3.584-8,8c0,13.232-10.768,24-24,24s-24-10.768-24-24   c0-4.416-3.576-8-8-8s-8,3.584-8,8c0,22.056,17.944,40,40,40S160,158.056,160,136z" />
        </g></g> </svg>
        <p>{{message}}</p>
    </div>
</template>

<script>
    export default {
        name: 'NothingToShow',
        props: {
            message: String
        }
    }
</script>

<style scoped>
    .nothing-to-show-wrapper{
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }

    .nothing-to-show-wrapper p{
        color: #4b4b5d;
        font-weight: 700;
        font-size: 20px;
    }

    .emoji-dark{
        fill: #3e3e4f;
    }

    .emoji-light{
        fill: var(--background);
    }
</style>