<template>
    <div class="limit-wrapper">
        <h1>Limit reached</h1>
        <p>You've reached the limit of the free tier:</p>
        <div class="limits">
            <p v-if="exceedsAddress">- {{ user.addresses + '/' + user.quota.addresses}} addresses</p>
            <p v-if="exceedsEmails">- {{user.stats.forwarded + '/' + user.quota.emails}} emails forwarded this month</p>
        </div>
        <p v-if="exceedsEmails">All incoming emails will be dropped until the counter resets on {{resetDate}}</p>
        <div style="text-align: center;">
            <button class="primary-button upgrade-button" @click="upgrade">Upgrade to remove limits</button>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'LimitReached',
        computed: {
            user(){
                return this.$store.state.user;
            },
            exceedsAddress(){
                return this.user.addresses >= this.user.quota.addresses
            },
            exceedsEmails(){
                return this.user.stats.forwarded >= this.user.quota.emails
            },
            resetDate(){
                let start = new Date(this.user.stats.start);
                let reset = new Date(start.setMonth(start.getMonth() + 1));
                return reset.toDateString()
            }
        },
        methods: {
            upgrade(){
                this.$router.replace('/settings/upgrade');
            }
        }
    }
</script>

<style scoped>
    .limit-wrapper {
        width: 100%;
        background: #E1707926;
        border: 1px solid var(--red-dark);
        border-radius: 10px;
        padding: 20px 20px;
        height: auto;
        margin-top: 5rem;
    }

    .limit-wrapper h1{
        margin: 0;
    }

    .limits {
        margin-left: 2rem;
        color: var(--font-light);
    }

    .upgrade-button {
        background: var(--red);
        color: var(--font);
        font-size: 17px;
        border-bottom: 5px solid var(--red-dark);
        margin-top: 1rem;
    }

    a{
        text-decoration: none;
        color: var(--primary);
    }
</style>