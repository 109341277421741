<template>
    <div :id="'item' + _id" class="item" :class="{darker: !active}">
        <div class="overview" :class="{'overview-expanded': expanded}" @click="expand">
            <div class="name">
                <font-awesome-icon icon="caret-down" class="expand-icon" :class="{ 'expanded-icon': expanded }" @click.stop="expand"/>
                <p @click.stop="copyOnAddress($event, fullAddress)" v-tooltip="{ content: 'Click to copy address'}">{{address}}<span>@{{domain.name}}</span></p>
                <button class="copy-button" @click.stop="copy($event, fullAddress)" v-tooltip="{ content: 'Click to copy address'}">Copy</button>
            </div>
            <div class="info">
                <category-tag v-if="category != undefined" :name="category.name" :color="category.color" />
                <div class="stats">
                    <address-stats type="blocked" :count="blocked" icon="ban" v-tooltip="{ content: 'Blocked emails'}"/>
                    <address-stats type="forwarded" :count="forwarded" icon="arrow-right" v-tooltip="{ content: 'Forwarded emails'}"/>
                </div>
                <div class="status">
                    <p v-if="temporary === true" v-tooltip="{ content: 'Temporary address: gets deleted after 1 use', container: '#item' + _id}">TEMP</p>
                    <p v-else-if="active" v-tooltip="{ content: 'Click to stop forwarding', container: '#item' + _id}" @click.stop="pause(_id)">ACTIVE</p>
                    <p v-else v-tooltip="{ content: 'Click to start forwarding', container: '#item' + _id}" @click.stop="start(_id)">PAUSED</p>
                </div>
            </div>
            <div class="status-dot">
                <font-awesome-icon v-if="temporary === true" icon="stopwatch" />
                <div v-else-if="active" class="dot active" v-tooltip="{ content: 'active'}" @click.stop="pause(_id)"></div>
                <div v-else class="dot paused" v-tooltip="{ content: 'paused'}" @click.stop="start(_id)"></div>
                <transition name="fade">
                    <div v-if="loadingAddress === _id">
                        <div class="spinner fast"></div>
                        <div v-if="loadingAddress === _id" class="spinner slow"></div>
                    </div>
                </transition>
            </div>
        </div>
        <transition name="expand">
            <div v-if="expanded" class="expanded">
                <div class="time-stats">
                    <address-time-stats v-if="temporary" text="Expires after" :temp="true" date="1 use" />
                    <address-time-stats v-else text="Last used" :date="lastReceived" />
                    <address-time-stats text="Created" :date="createdAt" />
                </div>
                <div class="stats-mobile">
                    <address-stats type="blocked" :count="blocked" icon="ban" :mobile="true" v-tooltip="{ content: 'Blocked emails'}" />
                    <address-stats type="forwarded" :count="forwarded" icon="arrow-right" :mobile="true" v-tooltip="{ content: 'Forwarded emails'}" />
                </div>
                <div class="actions">
                    <button class="copy-button-mobile" @click.stop="copy($event, fullAddress)">Copy</button>
                    <button class="change-button" @click.stop="toggleCategoryDropdown">Change category</button>
                    <button class="delete-button" @click="deleteAddress(_id)">Delete</button>
                    <div v-if="showDropdownList" class="dropdown-list" v-click-outside="toggleCategoryDropdown">
                        <p v-for="category in categories" :key="category._id" @click="changeCategory(_id, category._id)"><span class="dropdown-dot" :style="`background: ${category.color}`"></span>{{category.name}}</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import CategoryTag from '@/components/atoms/CategoryTag'
    import AddressStats from '@/components/atoms/AddressStats'
    import AddressTimeStats from '@/components/atoms/AddressTimeStats'
    import { Action } from '@/store/address/types'

  export default {
    name: 'Address',
    data(){
        return {
            expanded: false,
            showDropdownList: false
        }
    },
    components: {
        CategoryTag,
        AddressStats,
        AddressTimeStats
    },
    props: {
        _id: String,
        address: String,
        fullAddress: String,
        active: Boolean,
        temporary: {
            type: Boolean,
            required: false,
            default: false
        },
        domain: {
            id: String,
            name: String
        },
        category: {
            id: String,
            name: String,
            color: String
        },
        blocked: Number,
        forwarded: Number,
        lastReceived: Number,
        createdAt: Number
    },
    methods: {
        expand(){
            this.expanded = !this.expanded;
        },
        copy(e, value){
            const tempInput = document.createElement('INPUT');
            document.body.appendChild(tempInput);
            tempInput.setAttribute('value', value)
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
            const el = e.target;
            el.innerText = "Copied!";
            setTimeout(function() {
                el.innerText = "Copy";
            }, 3000);
        },
        copyOnAddress(e, value){
            const tempInput = document.createElement('INPUT');
            document.body.appendChild(tempInput);
            tempInput.setAttribute('value', value)
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
        },
        pause(id){
            this.$store.dispatch(Action.CHANGE_STATUS, { id: id, active: false });
        },
        start(id){
            this.$store.dispatch(Action.CHANGE_STATUS, { id: id, active: true });
        },
        deleteAddress(id){
            this.$store.dispatch(Action.DELETE_ADDRESS, id);
        },
        changeCategory(id, category){
            this.$store.dispatch(Action.CHANGE_CATEGORY, { id: id, category: category });
            this.showDropdownList = false;
        },
        toggleCategoryDropdown(){
            this.showDropdownList = !this.showDropdownList;
        },
    },
    computed: {
        categories: function(){
            return this.$store.state.category.categories;
        },
        loadingAddress: function(){
            return this.$store.state.address.loadingAddress;
        }
    }
  }
</script>

<style scoped>
    .item{
        width: 100%;
        margin-bottom: 1rem;
        position: relative;
    }

    .darker{
        opacity: 0.3;
    }

    .overview{
        display: flex;
        height: 60px;
        background: var(--background-light);
        border-radius: 5px;
        cursor: pointer;
    }

    .overview-expanded{
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .expanded{
        height: 100px;
        background: var(--background-accent);
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        display: flex;
    }

    .expand-icon{
        margin-right: 0.7rem;
        cursor: pointer;
        padding: 10px 10px;
        transition: all 0.3s;
    }

    .expanded-icon {
        transform: rotate(180deg);
    }

    .time-stats{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 2rem;
    }

    .actions{
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    button{
        -moz-appearance: none;
        appearance: none;
        border: 0;
        padding: 8px 20px;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
        margin-right: 1rem;
    }

    button:active{
        transform: scale(0.95);
    }

    .delete-button{
        background: var(--red);
        color: var(--font);
        font-size: 17px;
        border-bottom: 5px solid var(--red-dark) !important;
    }

    .change-button{
        background: var(--background);
        color: var(--font);
        font-size: 17px;
        border-bottom: 5px solid var(--background-dark) !important;
    }

    .copy-button{
        background: var(--background);
        color: var(--font);
        font-size: 13px;
        border-bottom: 2px solid var(--background-dark) !important;
        margin-left: 1rem;
    }

    .copy-button-mobile{
        display: none;
    }

    @media (hover: hover) and (pointer: fine) {
        .copy-button{
            display: none;
        }
    }

    .name{
        display: flex;
        align-items: center;
        margin-left: 1rem;
        font-size: 18px;
        cursor: initial;
    }

    .name p{
        font-weight: 700;
        cursor: pointer;
    }

    .name span{
        margin-left: 5px;
        color: var(--font-light);
        font-style: italic;
        font-weight: 500;
    }

    .info{
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 1rem;
        cursor: initial;
    }

    .stats{
        display: flex;
    }

    .stats-mobile{
        display: none;
    }

    .status{
        font-weight: 700;
        margin-left: 1.5rem;
        width: 70px;
        text-align: center;
        cursor: pointer;
    }

    .status-dot{
        width: 20px;
        height: 20px;
        position: absolute;
        right: -10px;
        top: -10px;
    }

    .status-dot .dot {
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }

    .spinner {
        border-style: solid;
        border-width: 2px;
        border-radius: 100%;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: -3px;
        left: -3px;
        color: transparent;
        animation-name: rotate;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        opacity: 1;
    }

    .fast {
        border-right-color: var(--font);
        animation-duration: .5s;
    }

    .slow {
        border-right-color: var(--primary);
        animation-duration: 1s;
    }

    @keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s ease;
    }
    .fade-enter, .fade-leave-to{
        opacity: 0;
    }

    .status-dot svg{
        width: 24px;
        height: 24px;
        position: absolute;
        top: -12px;
    }

    .active{
        background: var(--primary);
    }

    .paused{
        background: var(--red);
    }

    .dropdown-list{
        background: var(--background-dark);
        text-align: center;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
        width: 140px;
        border-radius: 5px;
        position: absolute;
        z-index: 1;
    }

    .dropdown-list p{
        margin: 0;
        padding: 10px 10px;
        cursor: pointer;
    }

    .dropdown-list p:hover{
        background: var(--background-dark);
        filter: brightness(90%);
    }

    .dropdown-dot{
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin-right: 0.4rem;
        display: inline-block;
    }

    .expand-enter-active {
        animation: expand-in 0.4s;
    }
    .expand-leave-active {
        animation: expand-in 0.4s reverse;
    }

    .expand-leave-active .stats-mobile{
        opacity: 0;
    }

    .expand-enter-active .stats-mobile{
        opacity: 0;
    }

    @keyframes expand-in {
        0% {
            height: 0;
            opacity: 0;
        }
        20% {
            opacity: 0;
        }
        85% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }

    @media only screen and (max-width: 1000px) {
        .item{
            margin-bottom: 0.5rem;
        }

        .name{
            font-size: 14px;
            margin-left: 0.5rem;
        }

        .name span{
            margin-left: 2px;
        }

        .expand-icon{
            margin-right: 0;
        }

        .copy-button{
            font-size: 11px;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }

        .info{
            margin-right: 0.5rem;
        }

        .status{
            margin-left: 0.5rem;
            width: 60px;
        }

        .actions{
            align-items: flex-end;
        }

        .delete-button{
            font-size: 12px;
            margin-bottom: 0.5rem;
            border-bottom: 2px solid var(--red-dark) !important;
        }

        .change-button{
            font-size: 12px;
            margin-bottom: 0.5rem;
            border-bottom: 2px solid var(--background-dark) !important;
        }
    }

    @media only screen and (max-width: 700px) {
        .stats{
            display: none;
        }

        .stats-mobile{
            display: flex;
            position: absolute;
            right: 10px;
            top: 70px;
        }
    }

    @media only screen and (max-width: 600px) {
        .overview{
            height: 45px;
        }

        .name{
            font-size: 10px;
            margin-left: 5px;
        }

        .copy-button{
            display: none;
        }

        .copy-button-mobile{
            background: var(--background);
            color: var(--font);
            font-size: 8px;
            border-bottom: 2px solid var(--background-dark) !important;
            margin-left: 0.5rem;
            margin-bottom: 0.5rem;
        }

        .status{
            width: 40px;
        }

        .temporary{
            width: 14px;
            height: 14px;
            right: -7px;
            top: -7px;
        }

        .status-dot{
            width: 10px;
            height: 10px;
            border-radius: 10px;
            right: -5px;
            top: -5px;
        }

        .stats-mobile{
            top: 50px;
        }

        .expanded{
            height: 60px;
        }

        .change-button,
        .delete-button{
            font-size: 8px;
        }

        .expanded button{
            padding: 5px 10px !important;
            margin-right: 0.5rem;
        }
    }
</style>