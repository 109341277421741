<template>
    <div class="create-address-wrapper">
        <div class="new-wrapper">
            <font-awesome-icon icon="times" class="close-icon" @click="showCreateAddress = false" />
            <div class="head">
                <h1>New Address</h1>
                <p>Enter a custom address, or create a random address by clicking the random icon.</p>
            </div>
            <div class="new-row">
                <div class="new-input">
                    <input placeholder="address" type="search" autocomplete="false" v-model="address" @focus="handleInputFocus" @blur="handleInputBlur" />
                    <font-awesome-icon icon="random" @click="randomAddress" v-tooltip="{ content: 'Generate random address'}" />
                </div>
                <dropdown class="new-extended" color="var(--grey)" :value="'@' + domain.name" v-tooltip="{ content: 'Select domain'}" :noBorder="true" @keypress.native="handleEnter">
                    <p tabindex="0" v-for="domainItem in domains" :key="domainItem._id" @click="selectDomain(domainItem._id)">{{domainItem.name}}</p>
                </dropdown>
                <dropdown color="var(--grey)" :value="category.name" v-tooltip="{ content: 'Select category'}" @keypress.native="handleEnter">
                    <p tabindex="0" v-for="categoryItem in categories" :key="categoryItem._id" @click="selectCategory(categoryItem._id)"><span class="dropdown-dot" :style="`background: ${categoryItem.color}`"></span>{{categoryItem.name}}</p>
                </dropdown>
            </div>
            <div class="temporary-wrapper">
                <div class="temporary-select">
                    <input class="inp-cbx" v-model="temporary" id="temporary" type="checkbox" style="display: none" />
                    <label class="cbx" for="temporary">
                        <span>
                            <svg width="12px" height="10px">
                                <use xlink:href="#check"></use>
                            </svg>
                        </span>
                        <span>Temporary address</span>
                    </label>
                    <svg class="inline-svg">
                        <symbol id="check" viewbox="0 0 12 10">
                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </symbol>
                    </svg>
                </div>
                <span class="info">
                    <font-awesome-icon icon="question" />Address will be deleted after one use</span>
            </div>
            <button class="primary-button create-button" @click="create">Create</button>
        </div>
    </div>
</template>

<script>
    import { Action, Mutation } from '../store/address/types'
    import { Action as DomainAction } from '../store/domain/types'
    import Dropdown from '@/components/Dropdown'

    export default {
        name: 'CreateAddress',
        data() {
            return {
                address: '',
                domain: {
                    _id: undefined,
                    name: 'mailpal.cc'
                },
                category: {
                    _id: undefined,
                    name: 'Category'
                },
                temporary: false
            }
        },
        computed: {
            showCreateAddress: {
                get: function () {
                    return this.$store.state.address.showNewAddress;
                },
                set: function (newValue) {
                    this.$store.commit(Mutation.UPDATE_NEW_ADDRESS_SHOW, newValue);
                }
            },
            categories: function () {
                return this.$store.state.category.categories.filter(category => category.name !== this.newAddressCategory)
            },
            domains: function () {
                return this.$store.state.domain.domains.filter((item) => item.verified === true);
            }
        },
        methods: {
            create() {
                this.copyToClipboard(`${ this.address }@${ this.domain.name }`)
                this.$store.dispatch(Action.CREATE_ADDRESS, { address: this.address, domain: this.domain._id, category: this.category._id, temporary: this.temporary })
                this.resetCategoryTitle()
            },
            selectCategory(id) {
                this.category = this.categories.find((item) => item._id === id)
            },
            selectDomain(id) {
                this.domain = this.domains.find((item) => item._id === id)
            },
            randomAddress() {
                const arr = '123456789abcdefghjkmnpqrstuvwxyzabcdefghjkmnpqrstuvwxyz'
                for (let i = 0; i < 5; i++) {
                    setTimeout(() => {
                        let randomString = '';
                        for (let n = 12; n > 0; n--) {
                            randomString += arr[Math.floor(Math.random() * arr.length)];
                        }
                        this.address = randomString;
                    }, 90)
                }
            },
            handleInputFocus() {
                if (this.address.length === 0) {
                    document.title = "New Address - MailPal"
                } else {
                    document.title = `New: ${ this.address }@${ this.domain.name } - MailPal`
                }
            },
            handleInputBlur() {
                if (this.address.length === 0) {
                    this.resetCategoryTitle();
                }
            },
            handleEnter: function (e) {
                const keycode = e.keyCode ? e.keyCode : e.which;
                if (keycode === 13) {
                    e.target.click();
                }
            }
        },
        components: {
            Dropdown
        },
        created() {
            this.$store.dispatch(DomainAction.GET_DOMAINS).then((result) => {
                this.domain = result[0]
            })
        }
    }
</script>

<style scoped>
    .create-address-wrapper{
        display: flex;
        background: var(--background-light);
        border: 2px solid var(--background-accent);
        border-radius: 10px;
        padding: 20px 30px;
        position: relative;
        height: auto;
        margin-top: 5rem;
    }

    .result{
        display: flex;
        align-items: center;
    }

    .result p{
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .result span{
        color: var(--primary);
        font-weight: 650;
    }

    .new-wrapper {
        width: 50%;
    }

    .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .head h1 {
        margin: 0;
    }

    .head p {
        max-width: 400px;
        color: var(--font-light);
        margin-top: 5px;
    }

    .new-row {
        display: flex;
    }

    .new-input {
        background: var(--background-accent);
        border-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 250px;
        display: flex;
        height: 40px;
        align-items: center;
    }

    .new-input:focus-within,
    .new-input:focus {
        filter: brightness(108%);
        box-shadow: 0 0 5px 4px rgba(171, 171, 192, 0.03);
    }

    .new-input input {
        border: none;
        outline: 0;
        background: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        color: var(--font-light);
        font-weight: 700;
        margin-left: 10px;
        margin-right: 1rem;
        width: 100%;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--font-lighter);
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--font-lighter);
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--font-lighter);
    }

    .new-input svg {
        margin-right: 1rem;
        color: var(--font-lighter);
        cursor: pointer;
    }

    .new-input svg {
        filter: brightness(105%);
    }

    .new-extended {
        margin-right: 1rem;
    }

    .temporary-select {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        margin-bottom: -5px;
    }

    .cbx {
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 6px;
        overflow: hidden;
        transition: all 0.2s ease;
    }

    .cbx:not(:last-child) {
        margin-right: 6px;
    }

    .cbx:hover {
        background: #4e4e666b;
    }

    .cbx span {
        float: left;
        vertical-align: middle;
        transform: translate3d(0, 0, 0);
    }

    .cbx span:first-child {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        transform: scale(1);
        border: 1px solid #cccfdb;
        transition: all 0.2s ease;
        box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
    }

    .cbx span:first-child svg {
        position: absolute;
        top: 3px;
        left: 2px;
        fill: none;
        stroke: var(--font-dark);
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
    }

    .cbx span:last-child {
        padding-left: 8px;
        line-height: 18px;
    }

    .cbx:hover span:first-child {
        border-color: var(--primary);
    }

    .inp-cbx {
        position: absolute;
        visibility: hidden;
    }

    .inp-cbx:checked+.cbx span:first-child {
        background: var(--primary);
        border-color: var(--primary);
        animation: wave 0.4s ease;
    }

    .inp-cbx:checked+.cbx span:first-child svg {
        stroke-dashoffset: 0;
    }

    .inline-svg {
        position: absolute;
        width: 0;
        height: 0;
        pointer-events: none;
        user-select: none;
    }

    @-moz-keyframes wave {
        50% {
            transform: scale(0.9);
        }
    }

    @-webkit-keyframes wave {
        50% {
            transform: scale(0.9);
        }
    }

    @-o-keyframes wave {
        50% {
            transform: scale(0.9);
        }
    }

    @keyframes wave {
        50% {
            transform: scale(0.9);
        }
    }

    .info {
        color: var(--font-light);
        font-size: 11px;
        padding-left: 8px;
    }

    .info svg {
        margin-right: 5px;
    }

    .create-button {
        background: var(--primary);
        color: var(--font-dark);
        font-size: 17px;
        border-bottom: 5px solid #63C6B1;
        margin-top: 1rem;
    }
</style>