export const Sort_Options = {
    CREATED: "createdAt",
    USED: "lastReceived",
    NAME: "address",
    CATEGORY: "category.name"
}

export function sortAddresses(addresses, category, search, sortAfter, sortOrder, status){
    let result;
    if(search.length > 0){
        result = filterSearch(addresses, search);
    }else{
        result = filterCategory(addresses, category);
        result = filterStatus(result, status);
    }
    
    result.sort((a, b) => {
        if(sortAfter === "CREATED" || sortAfter === "USED"){
            if(a[Sort_Options[sortAfter]] === undefined){
                return -1
            }else if(b[Sort_Options[sortAfter]] === undefined){
                return 1
            }else {
                return a[Sort_Options[sortAfter]] - b[Sort_Options[sortAfter]];
            }
        }else if(sortAfter === "NAME"){
            if(a[Sort_Options[sortAfter]].toUpperCase() < b[Sort_Options[sortAfter]].toUpperCase()){
                return -1;
            }else if(a[Sort_Options[sortAfter]].toUpperCase() > b[Sort_Options[sortAfter]].toUpperCase()){
                return 1;
            }else{
                return 0;
            }
        }else if(sortAfter === "CATEGORY"){
            if(a.category === undefined || a.category.name === undefined){
                return 1
            }else if(b.category === undefined || b.category.name === undefined){
                return -1;
            }else if(a.category.name.toUpperCase() < b.category.name.toUpperCase()){
                return -1;
            }else if(a.category.name.toUpperCase() > b.category.name.toUpperCase()){
                return 1;
            }else{
                return 0;
            }
        }
    })
    if(sortAfter === "CREATED" || sortAfter === "USED"){
        result.reverse();
    }
    if(sortOrder === "DESC"){
        result.reverse();
    }
    return result;
}

function filterSearch(addresses, search){
    return addresses.filter(address => {
        return address.fullAddress.toLowerCase().includes(search);
    })
}

function filterCategory(addresses, category){
    return addresses.filter(address => {
        return category === 'all' || (address.category !== undefined && address.category !== null && address.category._id === category)
    })
}

function filterStatus(addresses, status){
    return addresses.filter(address => {
        if(status === 'all'){
            return true;
        }else if(status === 'active'){
            return address.active === true;
        }else if(status === 'inactive'){
            return address.active === false;
        }
    })
}
