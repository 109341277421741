<template>
    <div class="search" v-tooltip="{ content: 'Search for addresses'}" role="search">
        <font-awesome-icon class="search-icon" icon="search"/>
        <input class="search-input" type="search" placeholder="Quick search" v-model="search" @focus="handleSearchFocus" @blur="handleSearchBlur"/>
        <font-awesome-icon v-if="search.length > 0" class="search-icon-clear" icon="times" @click="clear"/>
    </div>
</template>

<script>
    import { Mutation } from '../../store/address/types'

    export default {
        name: 'Search',
        computed: {
            search: {
                get: function(){
                    return this.$store.state.address.search;
                },
                set: function(newValue){
                    document.title = "Search: " + newValue + " - MailPal"
                    this.$store.commit(Mutation.UPDATE_SEARCH, newValue); 
                }
            },
            status: function(){
                return this.$store.state.status;
            },
        },
        methods: {
            clear: function(){
                this.$store.commit(Mutation.CLEAR_SEARCH);
                this.resetCategoryTitle();
            },
            handleSearchFocus(){
                if(this.search.length === 0){
                    document.title = "Search for Addresses - MailPal"
                }else{
                    document.title = "Search: " + this.search + " - MailPal"
                }
            },
            handleSearchBlur(){
                if(this.search.length === 0){
                    this.resetCategoryTitle();
                }
            }
        }
    }
</script>

<style scoped>
    .search{
        background: var(--background-accent);
        border-radius: 5px;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .search-wrapper:focus-within{
        filter: brightness(108%);
    }

    .search-input{
        margin-left: 8px;
        border:none;
        background:none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        color: var(--font-light);
        font-weight: 700;
    }

    .search-icon{
        margin-left: 1rem;
        color: var(--font-light);
    }

    .search-icon-clear{
        margin-left: auto;
        margin-right: 1rem;
        color: var(--font-light);
        cursor: pointer;
    }

    ::placeholder {
        color: var(--font-light);
        font-weight: 700;
    }

    ::-webkit-input-placeholder {
        color: var(--font-light);
        font-weight: 700;
    }

    :-ms-input-placeholder {
        color: var(--font-light);
        font-weight: 700;
    }
</style>