<template>
    <div class="time-stat-item">
        <p>{{text}}:</p>
        <span>{{ temp ? date : displayDate(date) }}</span>
    </div>
</template>

<script>

    export default {
        name: 'AddressTimeStats',
        props: {
            text: String,
            date: [Number, String],
            temp: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>

<style scoped>
    .time-stat-item{
        display: flex;
        align-items: center;
    }

    .time-stat-item p{
        margin: 0;
        color: var(--font-lighter);
        margin-right: 5px;
    }

    .time-stat-item span{
         font-weight: 650;
     }
</style>