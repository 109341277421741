<template>
    <div class="new-address-wrapper">
        <button class="primary-button new-address-button"><font-awesome-icon icon="plus" /> New Address</button>
    </div>
</template>

<script>

    export default {
        name: 'NewAddressButton',
        computed: {
            subscription(){
                return this.$store.state.user.subscription;
            },
        }
    }
</script>

<style scoped>
    .new-address-wrapper{
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .new-address-button{
        margin: 0;
        font-size: 17px;
        font-weight: 700;
        color: var(--font-dark);
        background: var(--primary);
        padding: 10px;
        border-radius: 5px;
    }

    .new-address-button svg{
        margin-right: 0.2rem;
    }
</style>